import { LogLevels, createConsola } from 'consola/browser'
import type { ConsolaReporter, LogObject } from 'consola'

// loglevel:
//  * warn|error => all
//  * info: only in issue report
//  * debug: only on dev


function stringify(v: any) {
    if (typeof v === 'object') {
        try {
            return JSON.stringify(v, (key, value) => (key.toLowerCase().indexOf('password') !== -1) ? '***' : value)
        } catch (e: any) {
            return 'object_not_serializable'
        }
    } else {
        return v
    }
}
class IssueReport implements ConsolaReporter {
    logs = [] as string[]

    LogLevelsTranslate = Object.keys(LogLevels).reduce((t, k) => { t[LogLevels[k]] = k.toUpperCase(); return t }, {} as Record<number, string>)

    public log(logObj: Pick<LogObject, 'level' | 'args'>): void {
        if (logObj && logObj.level <= LogLevels.info)
            this.logs.push(`[${this.LogLevelsTranslate[logObj.level]}] ${logObj.args.map(stringify).join(' ')}`)
    }

    reset() {
        this.logs = []
    }
}

export const issueReport = new IssueReport()

export default defineNuxtPlugin(nuxtApp => {
    if (import.meta.client) {

        nuxtApp.vueApp.config.errorHandler = (error, instance, info) => console.error(error)
        nuxtApp.hook('vue:error', (error, instance, info) => console.error(error))

        const config = useRuntimeConfig().public
        const logger = createConsola({ level: LogLevels.trace })
        logger.addReporter(issueReport)
        if (config.logSkipWrap !== true)
            logger.wrapAll()
        logger.level = config.logLevel ?? LogLevels.warn
        return { provide: { logger } }
    }
})