import { defineStore } from "pinia";

export const useStoreSettings = defineStore('settings', {

    state: () => {
        const localStorageState = JSON.parse(localStorage?.getItem('settings') || '{}') as { any?: any };

        return {
            any: {} as any,
            ...localStorageState
        }
    },

    actions: {}
})