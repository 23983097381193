import payload_plugin_qLmFnukI99 from "/myedvenn/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/myedvenn/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/myedvenn/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/myedvenn/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/myedvenn/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/myedvenn/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/myedvenn/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/myedvenn/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/myedvenn/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/myedvenn/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/myedvenn/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_logger_uvaXDPpqs9 from "/myedvenn/plugins/01.logger.ts";
import api_GFfDXud5Cr from "/myedvenn/plugins/api.ts";
import axios_QMFgzss1s4 from "/myedvenn/plugins/axios.ts";
import emitter_eaMgM0oZTb from "/myedvenn/plugins/emitter.ts";
import filter_MBkhYQrnZm from "/myedvenn/plugins/filter.ts";
import store_9xNuDHGAVU from "/myedvenn/plugins/store.ts";
import webcomponents_sQar4pXrQ0 from "/myedvenn/plugins/webcomponents.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  _01_logger_uvaXDPpqs9,
  api_GFfDXud5Cr,
  axios_QMFgzss1s4,
  emitter_eaMgM0oZTb,
  filter_MBkhYQrnZm,
  store_9xNuDHGAVU,
  webcomponents_sQar4pXrQ0
]