import type { AttendanceStatus, IAttendance } from "./api/myedvenn/attendance";
import type { IContact } from "./api/myedvenn/contact";
import type { IInterval } from "./api/myedvenn/interval";
import type { ITraining, ITrainingModule, ITrainingPart } from "./api/myedvenn/training";
import type { Days, Minutes } from "./plugins/filter";


export type MonthSummaryInterval = {
    attendance: IAttendance
    /** full interval */
    interval?: Readonly<IInterval>;
    /** start date on this month */
    start?: Date;
    startFirstDay: boolean;
    /** end date on this month */
    end?: Date;
    endLastDay: boolean;
    disabledTitle?: string
}

export type MonthSummary = {
    intervals: MonthSummaryInterval[];
    previousMonths: {
        workDone: Minutes;
        daysDone: Days;
    }
    /**
     * more important status
     * see: PRIORITY_STATUS
     */
    status: AttendanceStatus;
}

export enum TrainingStatus {
    NEXT = 1,
    WIP = 2,
    ENDED = 3,
}


export type TrainingModule = ITrainingModule & {
    wip: boolean
}

export type TrainingPart = ITrainingPart & {
    modules?: TrainingModule[];
}

export type Training = ITraining & {
    status: TrainingStatus

    loaded?: Date

    parts?: TrainingPart[];
}


export type Contact = IContact & {
    loaded?: Date
}

// // // // // //
export enum NotifLevel {
    info, warn, error
}

export type Notif = {
    group?: string;
    title: string;
    text: string;
    link?: string;
    linkText?: string;
    level?: NotifLevel
}
