import { TinyEmitter } from "tiny-emitter"
import { Notif, NotifLevel } from '@/types'
import { mailtoSupport } from "@/util"

export const rootEmitter = new TinyEmitter()
export function notify(notif: Notif) {
    rootEmitter.emit('notify', {
        notification: {
            group: "error",
            ...notif
        }
    })
}

function _notifyError(e: Error | string, level: NotifLevel, title = 'Erreur Technique') {
    const eMessage = typeof e === 'string' ? e : (e?.message as string || '')
    const notif: Notif = {
        title,
        text: eMessage,
        level
    }
    const errorId = /^\[([0-9]*)\]/.exec(eMessage)
    if (errorId) {
        notif.text = eMessage.replace(`[${errorId[1]}] `, '')
        notif.link = mailtoSupport(useNuxtApp()?.$store?.account?.user?.email, e)
        notif.linkText = 'Signaler un problème'
    }
    notify(notif)
}

export function notifyError(e: Error | string, title = '') {
    _notifyError(e, NotifLevel.error, title)
}

export function notifyWarn(e: Error | string, title = '') {
    _notifyError(e, NotifLevel.warn, title)
}

export function notifyInfo(e: Error | string, title = '') {
    _notifyError(e, NotifLevel.info, title)
}

function _throwError(err: Error, info?: string) {
    console.warn('error', err, 'info', info)

    const route = useRoute()
    route.params.error = err?.message
    route.meta.layout = "error"
}

export function throwFatalError(err: Error, info?: string) {
    _throwError(err, info)
    throw err
}

export default defineNuxtPlugin(ctx => {

    ctx.vueApp.config.errorHandler = (error: any, instance, info) => {
        if (info === "setup function")
            _throwError(error, info)
    }

    return {
        provide: {
            rootEmitter,
            notify
        }
    }
})