import { webComponents, type PluginOptions } from "@tolemac/web-components"

export default defineNuxtPlugin(app => {
    app.vueApp.use(webComponents, {
        formatter: {
            timezone: 'Europe/Paris',


        },
        translate(key, params) {
            // if(key === '')
            return key
        },
        // emitter
        dev: true,
        register: ['t-button', 't-input-password']
    } as PluginOptions)
})