import { acceptHMRUpdate, defineStore, Store, _Method } from 'pinia'
import { jwtDecode, type JwtPayload } from 'jwt-decode'

import { ITrainingPeriod } from '@/api/myedvenn/training'
import { IUser, IToken, TokenRight } from '@/api/myedvenn/user'

import { PiniaCustomProp } from '@/plugins/store'
import { Contact, Training, TrainingModule, TrainingStatus } from '@/types'
import { navGuardLoginUrl } from '@/middleware/public.global'

type Token = JwtPayload & IToken

type AccountState = {
  user: null | IUser;
  trainings: Record<number, Training>;
  contacts: Record<number, Contact>;

  trainingsLoaded: boolean;
  trainingsFullyLoaded: boolean;
  trainingIdWaiting?: number;

  token: null | {
    access: string;
    accessDecoded: Token;
    refresh: string;
    refreshDecoded: Token;
  },
  any: any
}

const state = () => {
  const localStorageState = JSON.parse(localStorage?.getItem('state') || '{}');
  console.debug(`[account]: localStorageState reload.`, localStorageState)

  return {
    user: null,
    trainings: {},
    contacts: {},
    token: null,
    ...localStorageState
  } as AccountState

}

const getters = {
  isInternal: (state: AccountState) => state.token?.accessDecoded?.rights?.some(r => r === TokenRight.INTERNAL) || false,
  allTrainings: (state: AccountState) => Object.values(state.trainings || {})
}

type ExtractGetters<T extends { [key: string]: _Method }> = {
  [key in keyof T]: ReturnType<T[key]>
}

type ThisAction = Store & PiniaCustomProp & AccountState & ExtractGetters<typeof getters>
type ExtractActions<T extends { [key: string]: _Method }> = {
  [key in keyof T]: ((...arg: Parameters<T[key]>) => ReturnType<T[key]>)
}

const contactLoading: Record<number, Promise<Contact>> = {}

const actions = {

  async login(this: ThisAction, email: string, password: string) {
    this.user = await this.$api.user.login({ email, password });
    await (this as any).refresh();
  },

  setToken(this: ThisAction, accessToken: string, refreshToken?: string) {
    if (accessToken) accessToken = accessToken.replace(/^Bearer /, '')
    if (refreshToken) refreshToken = refreshToken.replace(/^Bearer /, '')

    if (!accessToken && !refreshToken) this.token = null
    else this.token = {
      access: accessToken,
      refresh: refreshToken || this.token?.refresh || null,
      accessDecoded: accessToken ? jwtDecode<JwtPayload>(accessToken) : null,
      refreshDecoded: refreshToken ? jwtDecode<JwtPayload>(refreshToken) : (this.token?.refreshDecoded || null)
    }
  },

  async loadContact(this: ThisAction, contactId: number) {
    if (this.contacts[contactId] || this.contacts[contactId] === null)
      return

    if (contactLoading[contactId]) {
      await contactLoading[contactId]
      return
    }

    try {
      contactLoading[contactId] = this.$api.contact.get({ id: contactId })
      this.contacts[contactId] = await contactLoading[contactId]
      delete contactLoading[contactId]
    } catch (e) {
      console.warn('Contact not found props.contactId', e)
      this.contacts[contactId] = null
    }
  },

  async refresh(this: ThisAction) {
    if (this.token && !this.isInternal) {
      try {
        this.user = await this.$api.user.getme({})

        const now = new Date().getTime()

        function trainingStatus(periods: ITrainingPeriod[]): TrainingStatus {
          let status = TrainingStatus.NEXT
          periods.forEach((p, pi) => {
            if (pi === (periods.length - 1) && now > p.endAt) status = TrainingStatus.ENDED
            else if (now > p.startAt && now < p.endAt) status = TrainingStatus.WIP
          })
          return status
        }

        const loadingParts = []
        this.trainings = await this.$api.training.find({})
          .then(v => (v.values || []))
          .then(trainings => trainings.reduce<Record<number, Training>>((tot, t) => {

            loadingParts.push(
              (this as any).loadTrainingPart(t.id)
            );

            tot[t.id] = t as Training;
            tot[t.id].status = trainingStatus(t.intervals);
            return tot
          }, {}))

        this.trainingsLoaded = true
        Promise.all(loadingParts).then(() => this.trainingsFullyLoaded = true)
      } catch (e) {
        (this as any).logout()
      }
    }
  },

  async loadTrainingPart(this: ThisAction, id: number) {
    const now = new Date().getTime()
    function isModuleWIP(start: number, end: number) {
      if (!start) return false
      if (!end) return true
      if (start > end) return false
      return now < (end - 8640000)
    }
    const fullyLoaded = this.trainingsFullyLoaded
    if (fullyLoaded) this.trainingsFullyLoaded = false

    const _training = await this.$api.training.get({ id, relations: ['parts', 'waitingAttendance'] })

    _training.parts.forEach(p => p.modules.forEach(m => (m as TrainingModule).wip = isModuleWIP(m.start, m.end)))

    this.trainings[id].parts = _training.parts as any
    this.trainings[id].waitingAttendance = _training.waitingAttendance
    // Obsolete ??? => don't use `t.loaded = true` => trigger reactive property
    this.trainings[id].loaded = new Date()

    if (_training.waitingAttendance)
      this.trainingIdWaiting = _training.id
    else if (this.trainingIdWaiting === _training.id)
      this.trainingIdWaiting = undefined
    // this.waitingAttendance = _training.waitingAttendance || !this.allTrainings.some(t => !t.waitingAttendance)

    if (fullyLoaded) this.trainingsFullyLoaded = true
  },

  async logout(this: ThisAction) {
    try {
      await this.$api.user.logout({})
    } finally {
      const redirect = navGuardLoginUrl()
      if (redirect)
        this.$router.push(redirect)
      else
        this.$router.go(0)
    }
  },

  async logoutTo(this: ThisAction) {
    try {
      await this.$api.user.logout({})
    } finally {
      const redirect = navGuardLoginUrl(this.$router.currentRoute.value?.fullPath || window.location.pathname)
      if (redirect)
        this.$router.push(redirect)
      else
        this.$router.go(0)
    }
  }

}

export const useStoreAccount = defineStore<'account', AccountState, typeof getters, ExtractActions<typeof actions>>('account', {

  state,

  getters,

  actions: actions as ExtractActions<typeof actions>
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreAccount as any, import.meta.hot))
}