<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <Toasts group="error" position="tr" />
  </div>
</template>


<style lang="postcss">
@import '@tolemac/web-components/style.css';

section {

  &.account-section,
  &.password-section {
    @apply bg-white rounded-xl shadow mb-6 pt-4 my-4;

    /* content */
    >div {
      @apply p-5;

      /* title */
      >h2 {
        @apply text-lg font-medium;
      }

      /* subtitle */
      >p {
        @apply text-gray-500 pb-5;
      }

      input {
        @apply appearance-none block w-full md:w-2/3 px-3 py-2 my-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;

        &[hidden] {
          @apply hidden;
        }
      }

      textarea {
        @apply resize-none block border p-2 rounded-md my-4;
      }
    }

    /* buttons */
    >div:last-child {
      @apply p-2 bg-gray-50 rounded-b-xl flex flex-row-reverse flex-grow-0;
    }
  }
}
</style>