<template>
  <div class="bg-gray-50 py-1">

    <div v-if="training && training.intervals.length" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

      <header>

        <!-- name / date -->
        <h1 class="pt-12 text-3xl font-bold leading-tight text-center text-gray-900">{{ training.name }}</h1>
        <p class="text-center text-gray-500">
          {{ $filter.dateFormat(training.intervals[0].startAt) }} -
          {{ $filter.dateFormat(training.intervals[training.intervals.length - 1].endAt) }}
        </p>

        <!-- summary -->
        <div class="mx-auto mt-8 mb-12 sm:w-2/3 relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs">
          <div
            class="text-gray-500 hover:text-gray-700 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center">
            <div class="text-blue font-bold text-3xl">{{ assignSubmitted }}</div>
            <div>Corrections en attente</div>
          </div>
          <div
            class="text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center">
            <div class="text-blue font-bold text-3xl">{{ completion.toFixed(0) }} %</div>
            <div>Réalisé</div>
          </div>
        </div>

        <!-- progress bar -->
        <div class="my-12 flex relative w-full">
          <template v-for="part in training.parts">
            <template v-for="mod in part.modules">
              <!-- progress bar - modules -->
              <div class="bg-gray-300 h-4 text-center rounded" v-if="mod.periods.length"
                :title="mod.name + ' - ' + $filter.minutesToDuration(mod.periods[0].rawWorkDuration, true)"
                :style="{ width: '' + ((mod.periods[0].rawWorkDuration / training.intervals.find(i => i.id === mod.periods[0].intervalId).workDuration) * 100) + '%' }">
              </div>
            </template>
          </template>
          <!-- progress bar - theoritical -->
          <div class="absolute right-0 translate-y-5" :style="{ width: progress.width + '%' }">
            <div class="absolute -translate-y-11 translate-x-2" :style="{ right: progress.cursor + '%' }"
              title="avancement théorique">
              <MapPinIcon class="h-5 text-gray-500" />
            </div>
          </div>
          <!-- progress bar - real -->
          <div class="absolute translate-y-1 h-2 bg-gradient-to-r from-blue to-blue-800"
            :class="[(progress.width === 100) ? 'border-transparent' : 'border-gray-400']"
            :style="{ width: completion + '%' }">
            <div class="absolute translate-y-4 translate-x-2 rotate-180 right-0" title="avancement réel">
              <MapPinIcon class="h-5 text-blue-800" />
            </div>
          </div>
        </div>

        <!-- menu -->
        <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          <NuxtLink v-for="(tab, tabIdx) in tabs" :key="tab.name" :to="tab.href" custom
            v-slot="{ href, route, navigate, isActive, isExactActive }">
            <a :href="href" @click="navigate"
              :class="[isExactActive ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-20']"
              :aria-current="isExactActive ? 'page' : undefined">
              <span v-if="$store.display.breakpoint.mdAndUp">
                {{ tab.name }}
                <span v-if="tab.waitingAttendance"
                  class="h-2 w-2 inline-block align-middle rounded-full bg-orange-600"></span>
              </span>
              <component v-else :is="tab.icon" class="h-7 w-7 mx-auto" />
              <span aria-hidden="true"
                :class="[isExactActive ? 'bg-blue' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
            </a>
          </NuxtLink>
        </nav>
      </header>

      <main class="py-12">
        <NuxtPage :training="training" />
      </main>

    </div>

  </div>
</template>


<script lang="ts">
import { defineComponent, watch } from 'vue'
import { MapPinIcon, AcademicCapIcon, CalendarIcon, ClockIcon, DocumentTextIcon } from '@heroicons/vue/24/solid'
import { isWithinInterval, isValid } from 'date-fns'

import { Training } from '@/types'
import { IInterval } from '@/api/myedvenn/interval'

import { countDays } from '@/util'
import { TrainingAssignStatus } from '@/api/myedvenn/training'
import { Minutes } from '@/plugins/filter'
import { scrollTo } from '@/util'
import { throwFatalError } from '@/plugins/emitter'

export default defineComponent({
  components: {
    MapPinIcon
  },
  setup() {
    const { $store, $router } = useNuxtApp()

    const trainingId = computed(() => parseParams($router.currentRoute.value.params.id))

    const training = ref<Training>(null)
    const progress = ref({ width: 100, cursor: 0 })
    const assignSubmitted = ref(0)
    const completion = ref(0)

    const tabs = computed(() => [
      { name: 'Ma formation', icon: AcademicCapIcon, href: `/training/${trainingId.value}` },
      { name: 'Mon calendrier', icon: CalendarIcon, href: `/training/${trainingId.value}/calendar` },
      { name: 'Mes émargements', icon: ClockIcon, href: `/training/${trainingId.value}/attendance`, waitingAttendance: training.value.waitingAttendance },
      { name: 'Mon contrat', icon: DocumentTextIcon, href: `/training/${trainingId.value}/contract` }
    ])

    watch(() => trainingId.value ? ($store.account.trainings[trainingId.value] ? $store.account.trainings[trainingId.value].loaded : null) : undefined, trainingLoaded => {
      if (trainingLoaded) {

        // if (!$router.currentRoute.value.hash)
        //   window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })

        // training
        training.value = $store.account.trainings[trainingId.value];

        // progress
        const now = new Date().getTime()
        const currentPeriodIndex = training.value.intervals.findIndex(it =>
          isValid(it.startAt) && isValid(it.endAt) && isWithinInterval(now, { start: it.startAt, end: it.endAt })
        )

        if (currentPeriodIndex === -1)
          progress.value = { width: 100, cursor: 0 }
        else if (training.value.intervals.length)
          progress.value = cursorPosition(training.value.intervals[0], training.value.intervals[currentPeriodIndex])

        // completion
        const compl = calculateCompletion(training.value)
        completion.value = Math.min(compl.trainingDoneComplete, 100)
        assignSubmitted.value = compl.assignSubmitted

        if ($router.currentRoute.value.hash)
          scrollTo($router.currentRoute.value.hash.replace(/^#/, ''), 'animate-pulse', 4000)

      }
      if (trainingLoaded === null)
        throwFatalError(new Error(`Training ${trainingId.value} not found`))
    }, { immediate: true, flush: 'post' })

    return {
      training,
      assignSubmitted,
      completion,

      tabs,

      progress
    }
  }
})

function parseParams(param: string | string[]) {
  return parseInt(Array.isArray(param) ? param[0] : param)
}

function cursorPosition(firstTrainingSummary: IInterval, trainingSummary: IInterval) {
  const trainingDuration = countDays(trainingSummary.endAt, trainingSummary.startAt)
  const cursor = trainingDuration ? countDays(trainingSummary.endAt) / trainingDuration : 0
  const period = (trainingSummary.workDuration / firstTrainingSummary.workDuration)
  return {
    cursor: Math.max(Math.min(cursor, 1), 0) * 100,
    width: Math.max(Math.min(period, 1), 0) * 100
  }
}

function calculateCompletion(training: Training) {
  let trainingDoneComplete = 0 // percent
  let assignSubmitted = 0

  let _trainingDoneFull = 0 // percent
  training.parts.forEach(p => {
    p.modules.forEach(m => {

      let _moduleDoneComplete: Minutes = 0
      let _moduleAll: Minutes = 0

      let _moduleDoneFull: Minutes = 0
      m.sections.forEach(s => {

        s.assigns.forEach(a => {
          if (a.status === TrainingAssignStatus.SUBMITTED) assignSubmitted += 1
        })

        if (s.completion > 0) {
          _moduleDoneComplete = _moduleDoneFull + (s.workDuration * (s.completion / 100))
          _moduleDoneFull += s.workDuration
        }
        _moduleAll += s.workDuration
      })
      const _moduleCompletion = _moduleAll ? (_moduleDoneComplete / _moduleAll) : 0
      const _modulePart = (m.periods[0]?.rawWorkDuration || 0) / training.intervals[0].workDuration
      if (_moduleCompletion > 0) {
        trainingDoneComplete = _trainingDoneFull + (_moduleCompletion * _modulePart)
        _trainingDoneFull += _modulePart
      }
    })
  })

  return {
    trainingDoneComplete: trainingDoneComplete * 100,
    assignSubmitted
  }
}

</script>