import { defineNuxtPlugin } from '#app'

import axios, { Axios as AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'


class Axios {
    constructor(options: AxiosRequestConfig) {
        return axios.create(options)
    }
}

type AxiosExtended = AxiosInstance & NuxtAxiosInstance

class NuxtAxiosInstance extends Axios {

    constructor(options: AxiosRequestConfig) {
        super(options)
    }

    // $request<T = any>(config: AxiosRequestConfig): Promise<T> {
    //     return super.request(config)
    // }
    // $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    // $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    // $head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    // $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    // $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    // $put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    // $patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>

    setBaseURL(this: AxiosExtended, baseURL: string) {
        this.defaults.baseURL = baseURL
    }
    setHeader(this: AxiosExtended, name: string, value?: string | false, scopes?: string | string[]) {
        for (const scope of Array.isArray(scopes) ? scopes : [scopes]) {
            if (!value) {
                delete this.defaults.headers[scope][name];
                continue
            }
            this.defaults.headers[scope][name] = value
        }
    }
    setToken(this: AxiosExtended, token: string | false, type?: string, scopes?: string | string[]) {
        const value = !token ? null : (type ? type + ' ' : '') + token
        this.setHeader('Authorization', value, scopes)
    }
    // onRequest(this: AxiosExtended, fn: (config: AxiosRequestConfig) => void | AxiosRequestConfig | Promise<AxiosRequestConfig>) {
    //     this.interceptors.request.use(config => fn(config) || config)
    // }
    onResponse<T = any>(this: AxiosExtended, fn: (response: AxiosResponse<T>) => void | AxiosResponse<T> | Promise<AxiosResponse<T>>) {
        this.interceptors.response.use(response => fn(response) || response)
    }
    onError(this: AxiosExtended, fn: (error: AxiosError) => any) {
        this.onRequestError(fn)
        this.onResponseError(fn)
    }
    onRequestError(this: AxiosExtended, fn: (error: AxiosError) => any) {
        this.interceptors.request.use(undefined, error => fn(error) || Promise.reject(error))
    }
    onResponseError(this: AxiosExtended, fn: (error: AxiosError) => any) {
        this.interceptors.response.use(undefined, error => fn(error) || Promise.reject(error))
    }

    // create(options?: AxiosRequestConfig): NuxtAxiosInstance
}

// interface AxiosOptions {
//     baseURL?: string,
//     browserBaseURL?: string,
//     credentials?: boolean,
//     debug?: boolean,
//     host?: string,
//     prefix?: string,
//     progress?: boolean,
//     proxyHeaders?: boolean,
//     proxyHeadersIgnore?: string[],
//     proxy?: boolean,
//     port?: string | number,
//     retry?: boolean | IAxiosRetryConfig,
//     https?: boolean,
//     headers?: {
//         common?: Record<string, string>,
//         delete?: Record<string, string>,
//         get?: Record<string, string>,
//         head?: Record<string, string>,
//         post?: Record<string, string>,
//         put?: Record<string, string>,
//         patch?: Record<string, string>,
//     },
// }

export default defineNuxtPlugin(nuxtApp => {
    return {
        provide: {
            axios: new NuxtAxiosInstance({
                baseURL: 'http://localhost:3006'
            }) as AxiosExtended
        }
    }
})

export function useAxios() {
    return inject<AxiosExtended>('axios') as AxiosExtended;
}