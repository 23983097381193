import { TrainingStatus } from "@/types";
import { ContactRole } from "@/api/myedvenn/contact"
import { DocumentType } from "@/api/myedvenn/document";

export function padStartZero(item: number) {
    return `0${item}`.substr(-2)
}

export function minutesToDuration(minutes?: number, hideZeroMinute = false, withSpace = true) {
    if (minutes === null || minutes === undefined) return ''
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;

    return withSpace ?
        `${hour} h${(!min && hideZeroMinute) ? '' : ' ' + padStartZero((min))}`
        :
        `${hour}h${(!min && hideZeroMinute) ? '' : padStartZero((min))}`
}


/** (year * 100) + month (1..12) */
export type MonthYear = number
export type Minutes = number
export type Days = number

export const MONTH_FORMATER = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long' })
export const DAY_FORMATER = new Intl.DateTimeFormat('fr-FR', { weekday: 'short', day: 'numeric' })

/**
 * LocaleDateString UTC. ex: fr => 25/02/2012
 * @param date Date.getTime()
 */
export function dateFormat(date?: number | Date, hour = false) {
    if (!date) return ''
    try {
        if (typeof date === 'number') date = new Date(date)
        return hour ?
            date.toLocaleString('fr-FR', { timeZone: 'Etc/GMT' })
            :
            date.toLocaleDateString('fr-FR', { timeZone: 'Etc/GMT' })
    } catch (e: any) {
        console.warn(e)
        return ''
    }
}

export function dateISOString(date?: number | Date) {
    if (!date) return ''
    if (typeof date === 'number') date = new Date(date)
    return date.toISOString()
}



/**
 * Ex: 02/2012
 * @param ym year (ex:2012) or monthYear 201202
 * @param m month (1..12).
 */
export function monthFormat(ym: MonthYear | number, m?: number) {
    if (ym === undefined) return ''
    if (m !== undefined)
        return MONTH_FORMATER.format(new Date(ym, m - 1, 1))
    return MONTH_FORMATER.format(new Date(Math.floor(ym / 100), (ym % 100) - 1, 1))
}

export function numberFormat(v: number, precision = 0, unit = "") {
    return new Intl.NumberFormat('fr', {
        maximumFractionDigits: precision,
        useGrouping: true,
        minimumFractionDigits: precision
    }).format(v) + unit
}

export default defineNuxtPlugin(() => ({
    provide: {
        filter: {
            dateFormat,
            numberFormat,
            dateISOString,
            minutesToDuration,
            padStartZero,
            monthFormat,

            contactRoleFormat(role: ContactRole) {
                switch (role) {
                    case ContactRole.USER_ADVISER: return 'Conseiller formation'
                    case ContactRole.USER_MONITOR: return 'Référent pédagogique'
                    case ContactRole.USER_ASSISTANT: return 'Assistante'
                    default: return ''
                }
            },

            documentTypeFormat(a?: DocumentType): string {
                switch (a) {
                    case DocumentType.INVOICE: return 'Facture'
                    case DocumentType.PIF: return 'Protocole individuel de formation'
                    case DocumentType.TRAINING_AGREEMENT: return 'Convention'
                    case DocumentType.CONTRACT: return 'Contrat'
                    case DocumentType.TRAINING_CERTIFICATE: return 'Attestion de formation'
                    case DocumentType.ATTENDANCE_CERTIFICATE: return 'Attestion d’assiduité'
                    case DocumentType.ADMISSION_CERTIFICATE: return 'Attestion d’admission'
                    default: return ''
                }
            },

            trainingStatus(s: TrainingStatus) {
                switch (s) {
                    case TrainingStatus.NEXT: return 'À venir'
                    case TrainingStatus.WIP: return 'En cours'
                    case TrainingStatus.ENDED: return 'Terminé'
                    default: return ''
                }
            }

        }
    }
}))